// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-leitura-e-o-despertar-para-o-mundo-da-imaginacao-js": () => import("./../../../src/pages/a-leitura-e-o-despertar-para-o-mundo-da-imaginacao.js" /* webpackChunkName: "component---src-pages-a-leitura-e-o-despertar-para-o-mundo-da-imaginacao-js" */),
  "component---src-pages-almoxarifadokka-js": () => import("./../../../src/pages/almoxarifadokka.js" /* webpackChunkName: "component---src-pages-almoxarifadokka-js" */),
  "component---src-pages-almoxarifadoksa-js": () => import("./../../../src/pages/almoxarifadoksa.js" /* webpackChunkName: "component---src-pages-almoxarifadoksa-js" */),
  "component---src-pages-atividades-e-projetos-js": () => import("./../../../src/pages/atividades-e-projetos.js" /* webpackChunkName: "component---src-pages-atividades-e-projetos-js" */),
  "component---src-pages-base-js": () => import("./../../../src/pages/base.js" /* webpackChunkName: "component---src-pages-base-js" */),
  "component---src-pages-blog-item-js": () => import("./../../../src/pages/blog-item.js" /* webpackChunkName: "component---src-pages-blog-item-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-calendario-js": () => import("./../../../src/pages/calendario.js" /* webpackChunkName: "component---src-pages-calendario-js" */),
  "component---src-pages-coletanea-2021-na-escola-a-gente-constroi-muitas-historias-js": () => import("./../../../src/pages/coletanea-2021-na-escola-a-gente-constroi-muitas-historias.js" /* webpackChunkName: "component---src-pages-coletanea-2021-na-escola-a-gente-constroi-muitas-historias-js" */),
  "component---src-pages-contatos-js": () => import("./../../../src/pages/contatos.js" /* webpackChunkName: "component---src-pages-contatos-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid-19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-educacao-infantil-js": () => import("./../../../src/pages/educacao-infantil.js" /* webpackChunkName: "component---src-pages-educacao-infantil-js" */),
  "component---src-pages-ensino-fundametal-js": () => import("./../../../src/pages/ensino-fundametal.js" /* webpackChunkName: "component---src-pages-ensino-fundametal-js" */),
  "component---src-pages-estrutura-js": () => import("./../../../src/pages/estrutura.js" /* webpackChunkName: "component---src-pages-estrutura-js" */),
  "component---src-pages-estude-conosco-js": () => import("./../../../src/pages/estude-conosco.js" /* webpackChunkName: "component---src-pages-estude-conosco-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jonas-ribeiro-alegrou-o-dia-na-kingdom-school-unidade-kids-js": () => import("./../../../src/pages/jonas-ribeiro-alegrou-o-dia-na-kingdom-school-unidade-kids.js" /* webpackChunkName: "component---src-pages-jonas-ribeiro-alegrou-o-dia-na-kingdom-school-unidade-kids-js" */),
  "component---src-pages-jornada-multissensorial-de-2023-js": () => import("./../../../src/pages/jornada-multissensorial-de-2023.js" /* webpackChunkName: "component---src-pages-jornada-multissensorial-de-2023-js" */),
  "component---src-pages-kingdom-kids-js": () => import("./../../../src/pages/kingdom-kids.js" /* webpackChunkName: "component---src-pages-kingdom-kids-js" */),
  "component---src-pages-kingdom-promove-oficina-de-arte-inclusiva-em-homenagem-ao-bicentenario-da-independencia-e-aos-62-anos-de-brasilia-js": () => import("./../../../src/pages/kingdom-promove-oficina-de-arte-inclusiva-em-homenagem-ao-bicentenario-da-independencia-e-aos-62-anos-de-brasilia.js" /* webpackChunkName: "component---src-pages-kingdom-promove-oficina-de-arte-inclusiva-em-homenagem-ao-bicentenario-da-independencia-e-aos-62-anos-de-brasilia-js" */),
  "component---src-pages-kingdom-school-js": () => import("./../../../src/pages/kingdom-school.js" /* webpackChunkName: "component---src-pages-kingdom-school-js" */),
  "component---src-pages-leads-js": () => import("./../../../src/pages/leads.js" /* webpackChunkName: "component---src-pages-leads-js" */),
  "component---src-pages-lp-js": () => import("./../../../src/pages/lp.js" /* webpackChunkName: "component---src-pages-lp-js" */),
  "component---src-pages-matematica-de-singapura-js": () => import("./../../../src/pages/matematica-de-singapura.js" /* webpackChunkName: "component---src-pages-matematica-de-singapura-js" */),
  "component---src-pages-matriculas-abertas-js": () => import("./../../../src/pages/matriculas-abertas.js" /* webpackChunkName: "component---src-pages-matriculas-abertas-js" */),
  "component---src-pages-mensagem-da-direcao-js": () => import("./../../../src/pages/mensagem-da-direcao.js" /* webpackChunkName: "component---src-pages-mensagem-da-direcao-js" */),
  "component---src-pages-missao-visao-valores-js": () => import("./../../../src/pages/missao-visao-valores.js" /* webpackChunkName: "component---src-pages-missao-visao-valores-js" */),
  "component---src-pages-nossa-historia-js": () => import("./../../../src/pages/nossa-historia.js" /* webpackChunkName: "component---src-pages-nossa-historia-js" */),
  "component---src-pages-nossa-metodologia-js": () => import("./../../../src/pages/nossa-metodologia.js" /* webpackChunkName: "component---src-pages-nossa-metodologia-js" */),
  "component---src-pages-nossos-diferenciais-js": () => import("./../../../src/pages/nossos-diferenciais.js" /* webpackChunkName: "component---src-pages-nossos-diferenciais-js" */),
  "component---src-pages-nossos-pilares-js": () => import("./../../../src/pages/nossos-pilares.js" /* webpackChunkName: "component---src-pages-nossos-pilares-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */)
}

